import axios from 'axios';
import {
  LOG,
  ROOT_DOMAIN,
  PROTOCOL,
  DEV_ENV,
  BYPASS_SUBDOMAIN,
  SUBDOMAIN_KEY,
  STAFF_TOKEN_KEY,
} from '../config/constants';

export const _getURL = (url) => {
  const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
  let apiURL;

  /**
   * Evaluate whether or not to use subdomain to form apiURL
   * If either DEV_ENV or BYPASS_SUBDOMAIN is false, subdomain will be checked
   * Only when both are true, will the if case be bypassed.
   * The second half of if condition is to mitigate effort of logging in again and
   * again when spinning up development enivornment.
   * The second half will always be true when DEV_ENV is false so the BYPASS_SUBDOMAIN
   * matters only in Dev Env. It is useless in PROD env.
   */
  if (subdomain && (!DEV_ENV || !BYPASS_SUBDOMAIN)) {
    apiURL = `${PROTOCOL}://${subdomain}.${ROOT_DOMAIN}/api/v1/${url}`;
  } else {
    apiURL = `${PROTOCOL}://${ROOT_DOMAIN}/api/v1/${url}`;
  }
  if (LOG) { console.log('API URL', apiURL); }
  return apiURL;
};

export const GET = (url) => {
  if (LOG) { console.log('GET URL:', url); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.get(_getURL(url), { headers })
    .then(({ data }) => {
      _checkResponse(data);
      if (LOG) { console.log('Success:', data.data); }
      resolve(data.data);
    })
    .catch((error) => {
      _handleError(error, reject);
    });
  });
};

export const POST = (url, body) => {
  if (LOG) { console.log('POST URL:', url, ',body: ', body); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.post(_getURL(url), body, { headers })
    .then(({ data }) => {
      _checkResponse(data);
      if (LOG) { console.log('Success:', data.data); }
      resolve(data.data);
    })
    .catch((error) => {
      _handleError(error, reject);
    });
  });
};

export const PUT = (url, body) => {
  if (LOG) { console.log('PUT URL:', url, ',body: ', body); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.put(_getURL(url), body, { headers })
    .then(({ data }) => {
      _checkResponse(data);
      if (LOG) { console.log('Success:', data.data); }
      resolve(data.data);
    })
    .catch((error) => {
      _handleError(error, reject);
    });
  });
};

export const DELETE = (url) => {
  if (LOG) { console.log('DELETE URL:', url); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.delete(_getURL(url), { headers })
    .then(({ data }) => {
      _checkResponse(data);
      if (LOG) { console.log('Success:', data.data); }
      resolve(data.data);
    })
    .catch((error) => {
      _handleError(error, reject);
    });
  });
};

const _createHeaders = () => {
  const token = localStorage.getItem(STAFF_TOKEN_KEY);
  return {
    Authorization: `Bearer ${token}`
  };
};

const _handleError = (error, reject) => {
  if (error.response && error.response.data) {
    if (LOG) { console.log(error.response.data); }
    reject(error.response.data);
    return;
  }
  if (LOG) { console.log(error); }
  reject(error);
};

const _checkResponse = (data) => {
  if (!data || !data.success || data.code !== 200) {
    throw new Error('Malformed Response');
  }
};
