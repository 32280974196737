import { POST } from '../utilities/AxiosHelpers';

export function loginApi(username, password) {
  const formData = new FormData();
  formData.append('email', username);
  formData.append('password', password);
  return POST('staff/login', formData);
}

export function refreshTokenApi() {
  return POST('staff/refresh');
}

export function logoutApi() {
  return POST('staff/logout');
}

export const requestOtpApi = (data) => {
  return POST('staff/otp/send', data);
};

export const verifyOtpApi = (data) => {
  return POST('staff/otp/verify', data);
};

export const resetPasswordApi = (data) => {
  return POST('staff/forgot/change_password', data);
};

export const updatePasswordApi = (data) => {
  return POST('staff/update_password', data);
};

export const zoomUpdateApi = (data) => {
  return POST('staff/update_zoom', data);
};