import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.css';
import Main from './Containers/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./Containers/Main', () => {
    const NextApp = require('./Containers/Main').default;
    renderApp(NextApp);
  });
}
unregister();

// registerServiceWorker();
